html,
body {
  margin: 0;
  height: 100%;
}

body {
  background-color: #181818;
  font-size: 24px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

main {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}

main.name-select {
  background: url(./assets/bg.png);
  background-size: cover;
}

.logo {
  position: absolute;
  top: 24px;
  left: 36px;
}

p {
  margin: 0 0 5px 0;
}

input[type='text'] {
  font-family: Open Sans;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'onum' on;
  color: #2d2d2d;
  padding: 12px 66px 10px;
  background: #e0e0e0;
  box-shadow: inset 14px 8px 0px rgba(101, 101, 101, 0.8);
  outline: none;
  border: none;
  margin-bottom: 24px;
  max-width: 90%;
  box-sizing: border-box;
}

.btn {
  background-color: #504dd2;
  border: none;
  padding: 8px 64px;
  background: #504dd2;
  box-shadow: 4px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 162.5%;
  color: #f4f4f4;
  outline: none;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.horizontal {
  flex-direction: row;
}

.joystick {
  position: absolute;
  bottom: 20px;
  right: 24px;
  z-index: 100;
  display: none;
}

@media only screen and (max-width: 600px) {
  .joystick {
    display: block;
  }
}
